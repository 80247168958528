import * as React from "react"

import PickerScreen from '../../../components/pickerScreen'


const Picker = ({ data }) => (
  <PickerScreen
    question="What do you want to design?"
    gridify={true}
    options={[
      {
        label: "Vector graphics",
        caption: "(e.g. logo, infographics)",
        link: "/app/graphic-design/vector-graphics/"
      },
      {
        label: "Social media posts",
        caption: "(e.g. cover image, story)",
        link: "/app/graphic-design/social-media-posts/"
      },
      {
        label: "Print products",
        caption: "(e.g. flyers, business cards)",
        link: "/app/graphic-design/print-products/"
      },
      {
        label: "Slides",
        caption: "(e.g. presentation, deck)",
        link: "/app/graphic-design/slides/"
      }
  ]}/>
)

export default Picker;


